import { cleanUndefineds } from '../typebox';
import { R } from '../vendor/remeda';
import { encodeQs, formatApiPath, formatPath, formatQs } from './apiCommon';
import { type Endpoint } from '@getmo/common/endpoint';
import { type SchemedDecode, schemedDecode } from '@getmo/common/schemed';
import { type StaticDecode, type TSchema } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

type IfNotEmpty<K extends string, T extends TSchema> = [StaticDecode<T>] extends [never]
  ? { [key in K]?: never }
  : unknown extends StaticDecode<T>
    ? { [key in K]?: never }
    : { [key in K]: StaticDecode<T> };

export const formatApiUrl = <E extends Endpoint<TSchema, TSchema, TSchema, TSchema>>(
  endpoint: E,
  { params, qs }: IfNotEmpty<'params', E['params']> & IfNotEmpty<'qs', E['qs']>,
): string => {
  const path = formatApiPath(endpoint, params);

  if (!qs) {
    return path;
  }

  return path + formatQs(endpoint.qs, qs);
};

export const callApi = async <E extends Endpoint<TSchema, TSchema, TSchema, TSchema>>(
  endpoint: E,
  {
    headers = {},
    params,
    body,
    qs,
  }: IfNotEmpty<'params', E['params']> &
    IfNotEmpty<'body', E['body']> &
    IfNotEmpty<'qs', E['qs']> & {
      headers?: HeadersInit;
    },
): Promise<SchemedDecode<E['response']>> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let bodyData: any = Value.Encode(endpoint.body, cleanUndefineds(body));
  if (endpoint.consumes === 'multipart/form-data') {
    const form = new FormData();
    for (const [k, v] of R.entries(bodyData)) {
      if (v instanceof File) {
        form.append(k, v);
      } else {
        form.append(k, new Blob([JSON.stringify(v)], { type: 'application/json' }), '');
      }
    }
    bodyData = form;
  }
  if (endpoint.consumes === 'application/x-www-form-urlencoded') {
    bodyData = new URLSearchParams(bodyData);
  }

  const url = formatPath('/api', endpoint, params);
  const req = {
    method: endpoint.method,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: encodeQs(endpoint.qs, qs) as any,
    body: bodyData,
    headers,
  };

  const response = await $fetch(url, req);

  if (!Value.Check(endpoint.response, response)) {
    const err = new Error('Invalid response');
    err.cause = [...Value.Errors(endpoint.response, response)];
    throw err;
  }

  const value = schemedDecode(endpoint.response, response) as SchemedDecode<E['response']>;
  return value;
};
