import { type ModelWithRelationModels, type Path, PathType, sortOrder } from './typebox';
import { type StaticDecode, type TNever, type TObject, type TSchema, Type } from '@sinclair/typebox';

export type EndpointResponse<T extends Endpoint<TSchema, TSchema, TSchema, TSchema>> =
  T extends Endpoint<TSchema, TSchema, TSchema, infer R> ? StaticDecode<R> : never;

export type Endpoint<
  ParamsSchema extends TSchema,
  QsSchema extends TSchema,
  BodySchema extends TSchema,
  ResponseSchema extends TSchema,
> = {
  method: 'get' | 'post' | 'patch' | 'put' | 'delete';
  consumes: string;
  anonymous: boolean;
  path: string;
  params: ParamsSchema;
  qs: QsSchema;
  body: BodySchema;
  contentType?: string;
  response: ResponseSchema;
};

export const createEndpoint = <
  QsSchema extends TSchema,
  BodySchema extends TSchema,
  ResponseSchema extends TSchema,
  ParamsSchema extends TSchema = TNever,
>(endpoint: {
  method: 'get' | 'post' | 'patch' | 'put' | 'delete';
  consumes?: string;
  anonymous?: boolean;
  path: string;
  params?: ParamsSchema;
  qs?: QsSchema;
  body?: BodySchema;
  contentType?: string;
  response: ResponseSchema;
}): Endpoint<ParamsSchema, QsSchema, BodySchema, ResponseSchema> => ({
  consumes: 'application/json',
  anonymous: false,
  params: Type.Any() as unknown as ParamsSchema,
  qs: Type.Any() as unknown as QsSchema,
  body: Type.Any() as unknown as BodySchema,
  ...endpoint,
});

export type BaseColumnProperties<T> = {
  slotName?: string;
  field?: Path<T>;
  header?: string;
  formatValue?: (item: T) => string | undefined;
};

export const createListEndpoint = <
  FiltersSchema extends TObject,
  ItemSchema extends ModelWithRelationModels,
  ParamsSchema extends TSchema = TNever,
>(opts: {
  anonymous?: boolean;
  path: string;
  params?: ParamsSchema;
  filters: FiltersSchema;
  item: ItemSchema;
  columns?: NoInfer<BaseColumnProperties<StaticDecode<ItemSchema>>>[];
}) =>
  Object.assign(
    createEndpoint({
      method: 'get',
      qs: Type.Composite([
        opts.filters,
        Type.Object({
          page: Type.Optional(Type.Integer()),
          sortField: Type.Optional(PathType(opts.item)),
          sortOrder: Type.Optional(sortOrder),
          pageSize: Type.Optional(Type.Number({ maximum: 1000 })),
          include: Type.Optional(Type.Record(Type.String(), Type.Unknown())),
          columns: Type.Optional(Type.Array(Type.String())),
          format: Type.Optional(Type.Literal('xlsx')),
        }),
      ]),
      response: Type.Object({
        count: Type.Number(),
        results: Type.Array(opts.item),
      }),
      ...opts,
    }),
    {
      filters: opts.filters,
      item: opts.item,
      columns: (opts.columns ?? []) as unknown as BaseColumnProperties<TSchema>[],
    },
  );
export type ListEndpoint<
  FiltersSchema extends TObject = TObject,
  ItemSchema extends ModelWithRelationModels = ModelWithRelationModels,
  ParamsSchema extends TSchema = TNever,
> = ReturnType<typeof createListEndpoint<FiltersSchema, ItemSchema, ParamsSchema>>;
